.Home {
  .search-wrapper {
    .carousel {
      .slider {
        cursor: default;
      }

      .slide {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        min-height: 440px;
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          object-fit: cover;
        }

        .search-block {
          z-index: 1;
          padding: 20px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          gap: 15px;
          position: relative;

          .search-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-top: 20px;
            gap: 15px;

            .title {
              line-height: 1.2;
              max-width: 700px;
              text-align: center;
              white-space: initial;
              padding: 10px;
              font-size: 23px;
              font-weight: 900;
              font-family: "Din Condensed Bold", sans-serif;
              text-transform: uppercase;
              color: var(--textColor1);
              background: rgba(255, 255, 255, 1);
              border-radius: 5px;
            }

            .badge {
              width: 150px;
              height: 150px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: var(--color3);

              .badge-content {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                color: white;
                text-align: center;
              }
            }
          }

          .search-group {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            gap: 15px;

            .Search {
              svg {
                color: var(--color1);
              }
            }

            .search-btn {
              margin: 30px 0;
              width: 150px;
              height: 40px;
              border-radius: 30px;

              svg {
                display: none;
                height: 22px;
                width: 22px;
              }
            }
          }

          .partners-op {
            margin-top: 20px;
          }
        }
      }
    }
  }

  .main-container {
    padding: 40px 20px 0 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;

    .description {
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: -0.4px;
      text-align: left;
      color: var(--textColor1);
    }

    .partners {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 20px;
      position: relative;

      .carousel {
        .slider {
          cursor: default;
        }
      }

      .partner-carousel {
        width: 150px;
      }

      .partner {
        width: 150px;
        height: 150px;
        padding: 10px;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          padding: 20px;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      & > span {
        line-height: 1;
        font-size: 18px;
        font-weight: 500;
        min-width: 100px;
        max-width: 250px;
      }
    }

    .hero {
      width: 100%;
      padding: 20px 60px;

      .blocks {
        margin: 50px 0;

        &:first-child {
          margin-top: 0;
        }

        h3 {
          color: var(--color1);
          text-align: center;
          margin-bottom: 50px;
          font-size: 1.4em;
          line-height: 1.2;
          font-weight: bold;
          padding: 0;
        }

        &.how-it-works {
          .block-title {
            color: var(--color1);
          }

          .block-description {
            margin-top: -5px;
          }
        }

        &.new-partners-list, &.partners-list {
          img {
            height: 60px;
            max-width: 180px;
          }
        }

        &.talk-about-us {
          padding: 0 10px;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          gap: 20px;

          h3 {
            text-align: left;
            margin-bottom: 0;
            color: #112c97;
            font-size: 18px;
            text-transform: uppercase;
          }

          .talk {
            padding: 0 8px;
            border-left: 5px solid var(--color1);

            & > p {
              color: #666;
              font-weight: 700;
              font-size: 16px;
              line-height: 1.5;
            }
          }
        }

        .blocks-container {
          display: flex;
          align-items: flex-start;
          justify-content: space-around;
          flex-wrap: wrap;
          gap: 40px;
          margin-top: 20px;
        }
      }

      .referencing {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 30px;
        padding: 30px 15px;
        margin: 0 -80px;
        position: relative;
        width: calc(100% + 160px);
        background: var(--color1);

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: var(--color1);
          opacity: 0.5;
        }

        p {
          z-index: 2;
          color: white;
          max-width: 540px;
          text-align: center;
          font-size: 1.3em;
          line-height: 1.2;
          font-weight: 500;
        }

        & > a {
          z-index: 2;
        }
      }

      .partners-op {
        .link-btn {
          width: auto;
          margin: 0 auto;
        }
      }
    }
  }

  /* Desktop */
  @media screen and (min-width: 1024px) {
    .search-wrapper {
      .carousel {
        .slide {
          .search-block {
            .search-content {
              margin-top: 60px;
              flex-direction: row;
              justify-content: space-between;
              gap: 40px;

              .title {
                font-size: 44px;
                font-weight: bold;
                line-height: 1;
                text-align: left;
                padding: 25px;
              }
            }

            .search-group {
              margin-top: 30px;
              flex-direction: row;
              gap: 0;
              position: relative;

              .Search {
                &.search-control {
                  width: 175%;
                  border-radius: 30px 0 0 30px;
                  border-right: 0.5px solid var(--color1);
                }

                &.search-location {
                  border-radius: 0 30px 30px 0;
                }
              }

              .search-btn {
                width: auto;
                height: 100%;
                position: absolute;
                right: 0;
                margin: 0;
                border-radius: 50%;
                padding: 14px;
                border: 0.5px solid var(--color1);

                span {
                  display: none;
                }

                svg {
                  display: block;
                }
              }
            }

            .partners-op {
              margin-top: 40px;
            }
          }
        }
      }
    }

    .main-container {
      padding: 40px 40px 0 40px;

      .hero {
        padding: 20px 100px;

        .blocks {
          h3 {
            font-size: 25px;
            text-align: left;
          }

          &.new-partners-list, &.partners-list {
            .blocks-container {
              flex-wrap: nowrap;

              .Block {
                width: auto;
              }
            }
          }

          &.new-partners-list {
            .blocks-container {
              justify-content: flex-start;
            }
          }

          &.talk-about-us {
            flex-direction: row;
            flex-wrap: wrap;

            h3 {
              width: 100%;
            }

            .talk {
              width: 200px;
            }
          }
        }

        .referencing {
          width: 100%;
          margin: 0 auto;
          padding: 50px;

          p {
            font-size: 24px;
          }
        }
      }
    }
  }

  /* Small Screen */
  @media screen and (max-width: 480px) {
    .main-container {
      .hero {
        padding: 20px 0;

        .referencing {
          margin: 0 -20px;
          width: calc(100% + 40px);
        }
      }
    }
  }
}