.NotFound {
  .main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 40px;
    gap: 20px;
  }

  @media screen and (min-width: 1024px) {
  }
}