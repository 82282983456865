.Search {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  height: 50px;
  border-radius: 30px;
  background: #FFF;
  padding: 5px 20px;
  cursor: text;

  &.search-control {
    &:focus-within {
      svg {
        transform: rotate(90deg);
      }
    }
  }

  &.search-location {
    &:focus-within {
      svg {
        transform: rotate(45deg);
      }
    }
  }

  svg {
    height: 22px;
    width: 22px;
    transition: all 0.3s ease-in-out;
  }

  input {
    border: none;
    width: 100%;
    height: 100%;
    font-size: 14px;
  }
}
