body {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: var(--color1);
  }
}

#root {
  position: relative;
  height: 100%;
  box-sizing: border-box;
}

* {
  font-family: var(--fontFamily1);
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0;
  padding: 0;
}

strong {
  font-weight: 800;
}
