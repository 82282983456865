.App {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;

  .Page {
    padding-top: 80px;
    flex: 1;
  }

  @media screen and (min-width: 1024px) {
    .Page {
      padding-top: 100px;
    }
  }
}
