.GiftCard {
  .hero {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
    padding: 40px;
    background-position: center;

    .left, .right {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 25px;
    }

    .logo-wrapper {
      width: 125px;
      height: 125px;
      border-radius: 50%;
      padding: 10px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .title {
      font-size: 24px;
      line-height: 28px;
      font-weight: 700;
      color: #ffffff;
      padding: 0.8em;
      border-radius: 15px;
      background: var(--color1);
      width: 100%;
    }

    .subtitle {
      margin-top: -15px;
      font-size: 16px;
      line-height: 18px;
      font-weight: 600;
      color: #ffffff;
      padding: 0.8em;
      border-radius: 15px;
      background: var(--color1);
      width: 100%;
    }

    a {
      width: 100%;
    }

    .Button {
      border-radius: 10px;
      width: 100%;
    }

    .description {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      border-radius: 15px;
      padding: 20px;
      background: var(--color1);
      width: 100%;
      max-width: 500px;

      .title {
        text-align: center;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 18px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    .blocks {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 40px;

      .Block {
        width: auto;
        max-width: 300px;
        gap: 0;

        .block-icon {
          background: white;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          padding: 10px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;

          & > img {
            margin: 0;
          }
        }
        .block-title {color: white; font-size: 18px;}
        .block-subtitle {color: white; font-size: 16px;}
        .block-description {color: white; font-size: 14px;}
      }
    }
  }

  /* Desktop */
  @media screen and (min-width: 1024px) {
    .hero {
      flex-direction: row;

      .left {
        align-items: flex-start;

        .title {
          width: auto;
        }

        .subtitle {
          width: auto;
        }

        a {
          width: auto;
        }
      }

      .blocks {
        .block-title{font-size: 20px;}
        .block-subtitle{font-size: 18px;}
        .block-description{font-size: 16px;}
      }
    }
  }
}