.Block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5px;
  width: 200px;

  img {
    height: 70px;
    max-width: 100%;
    object-fit: contain;
  }

  .block-title {
    font-size: 14px;
    line-height: 1.4;
    font-weight: 700;
    color: var(--textColor1);
    text-align: center;
  }

  .block-subtitle {
    font-size: 14px;
    line-height: 1.4;
    font-weight: 600;
    color: var(--textColor1);
    text-align: center;
  }

  .block-description {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    color: var(--textColor1);
    text-align: center;
  }
}

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: var(--color1);
  color: #ffffff;
  font-weight: 500;
  padding: 0.8em;
  border: 1px solid var(--color1);

  &.btn-inverted {
    background: #ffffff;
    color: var(--color1);

    &:hover {
      background: var(--color1);
      color: #ffffff;
    }
  }

  &.link-btn {
    text-transform: uppercase;
    border-radius: 50px;
  }
}

.Footer {
  background: #f5f8fa;
  padding: 20px 0;
  width: 100%;

  .footer-wrapper {
    width: 95%;
    max-width: 1557px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    gap: 20px;

    .items-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      flex-direction: column;

      .item {
        font-size: 13px;
        font-weight: 500;
        line-height: 1.4;
        text-align: center;
        color: #002394;


        & > a, & > strong {
          color: #002394;
        }
      }
    }

    .socials-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 5px;

      img {
        height: 32px;
      }
    }
  }
}

.Header {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid var(--color1);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  box-shadow: 0 2px 10px 0 #909498;

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 90%;
    height: 100%;
    position: relative;

    .logos-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;

      .logo {
        max-height: 40px;
        object-fit: contain;
      }
    }

    .menu {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .burger-menu {
        &:hover {
          cursor: pointer;
        }

        .line {
          width: 30px;
          height: 3px;
          background-color: var(--color1);
          margin: 5px 0;
          transition: all 0.3s ease-in-out;
        }

        &.active {
          .line:nth-child(1) {
            transform: rotate(45deg) translate(4px, 7px);
          }

          .line:nth-child(2) {
            opacity: 0;
          }

          .line:nth-child(3) {
            transform: rotate(-45deg) translate(5px, -7px);
          }
        }
      }

      .links {
        position: absolute;
        background: #fff;
        border-top: 3px solid var(--color1);
        top: 100%;
        left: 0;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding: 5%;
        transform-origin: top;
        transform: scaleY(0);
        transition: transform 0.3s ease;
        overflow: hidden;

        &.active {
          transform: scaleY(1);
        }

        .link {
          border-bottom: 1px solid rgba(0,0,0,.03);
          padding: 10px 5%;
          transition: all 0.2s ease-in-out;
          width: 100%;

          & > a {
            color: var(--color2);
            font-weight: 600;
            font-family: var(--fontFamily1);
            font-size: 14px;
            line-height: 20px;
            text-decoration: none;
          }

          &:hover {
            opacity: 0.7;
            background: rgba(0,0,0,.03);
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .Block {
    width: 300px;

    img {
      height: 100px;
      margin-bottom: 10px;
    }

    .block-title {
      font-size: 18px;
    }

    .block-subtitle {
      font-size: 18px;
    }

    .block-description {
      font-size: 18px;
      margin-top: 20px;
    }
  }

  .Button {
    .btn {
      padding: 0.8em 1.7em;
      font-size: 18px;
    }
  }

  .Footer {
    .footer-wrapper {
      flex-direction: row;
      gap: 40px;

      .items-wrapper {
        flex-direction: row;
        gap: 40px;
      }
    }
  }

  .Header {
    height: 100px;

    .header-wrapper {
      .logo {
        max-height: 80px;
      }

      .menu {
        .burger-menu {
          display: none;
        }

        .links {
          position: relative;
          display: flex;
          align-items: stretch;
          justify-content: flex-end;
          flex-direction: row;
          border-top: none;
          gap: 60px;
          transform: scaleY(1);
          overflow: visible;
          height: 100%;
          top: auto;
          left: auto;
          padding: 5% 0;

          .link {
            border-bottom: none;
            border-left: 1px solid var(--color3);
            padding: 0 10px;
            width: auto;
            max-width: fit-content;

            & > a {
              text-transform: uppercase;
            }

            &:hover {
              background: white;
            }
          }
        }
      }
    }
  }
}
